import type { History } from 'history';
import { createBrowserHistory } from 'history';

let history: History = createBrowserHistory();

export const setHistory = (h: History) => {
  history = h;
};

export const getHistory = (): History => {
  return history;
};
